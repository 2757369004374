import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import postData from "../../utils/API";
import { Pagination, Button, Input, Tooltip, Typography } from "antd";

import { RollbackOutlined } from "@ant-design/icons";
import {
  formatDate,
  formatDateTime,
  numberFormatter,
  numberWithCommas,
} from "../../utils/utils";
import { useDispatch } from "react-redux";
import { status } from "../../features/statusSlice";

import CargoDetail from "./cargo-detail";

const { Search } = Input;

const { Text } = Typography;
function Cargo() {
  const dispatch = useDispatch();
  const [path, setPath] = React.useState("");
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(10);

  const [param, setParam] = React.useState({
    items: "10",
    offset: "0",
    search: "",
  });
  let location = useLocation();

  const getData = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "cargo",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success !== undefined && data.success === 1) {
        setData(data.data);
        setTotal(data.items);
      }
    });
  };

  React.useEffect(() => {
    document.title = "Cargo";
    const url = location.pathname.substring(1);
    if (path !== url) {
      param.items = 10;
      param.offset = 0;

      setParam({ ...param });
      setPath(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  const onSearch = (value) => {
    param.offset = 0;
    param.search = value;
    setParam({ ...param });
  };

  /** Match Status */
  const match = (load) => {
    let ends = [];
    let starts = [];
    let complete = 0;
    load.match.map((m) => {
      if (m.end_trip !== null) ends.push(m);
      if (m.start_trip !== null) starts.push(m);
      return m;
    });

    if (starts.length === ends.length) complete = ends.length;

    if (load.loads === complete) return <Text type="success">Delivered</Text>;
    if (starts.length > 0) return <Text type="warning">ONroute</Text>;
    return <Text type="danger">Pending</Text>;
  };

  return (
    <div className="content">
      <div className="content-header-container">
        <p className="content-header">Cargo ({numberWithCommas(total)})</p>
        <div className="pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={param.items}
              current={param.offset + 1}
              total={51}
            />
          </div>

          <div>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              allowClear
              style={{
                width: 200,
              }}
            />
            <Tooltip placement="topLeft" title={"Reload"}>
              <Button
                onClick={() => {
                  param.offset = 0;
                  setParam({ ...param });
                }}
                style={{ marginLeft: "10px" }}
                icon={<RollbackOutlined />}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="overflow-y-auto">
        <table>
          <thead>
            <tr>
              <th>Order no</th>
              <th>Action</th>
              <th>Requests</th>
              <th>Matched</th>
              <th>Delivered</th>
              <th>Vehicle Type</th>
              <th>Commodity</th>
              <th>No of trucks</th>
              <th>Weight</th>
              <th>Loading Point</th>
              <th>Loading Date</th>
              <th>Offloading Point</th>
              <th>Rate</th>
              <th>Rate Amount</th>
              <th>Payment Term</th>
              <th>Posted by</th>
              <th>Date Created</th>
            </tr>
          </thead>

          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={18}>
                  <p className="no-data">No data</p>
                </td>
              </tr>
            )}

            {_.map(data, (load, i) => (
              <tr key={i}>
                <td>{load.id}</td>
                <td>
                  <CargoDetail cargo={load} />
                </td>
                <td>{load.requests.length}</td>
                <td>{load.match.length}</td>
                <td>{match(load)}</td>
                <td>{load.type}</td>
                <td className="capitalize">{load.cargo}</td>
                <td className="text-end">{numberFormatter(load.loads)}</td>
                <td>
                  {numberFormatter(load.weight)} {load.weight_type}
                </td>
                <td>{load.loading_point}</td>
                <td>{formatDate(load.loading_date)}</td>
                <td>{load.offloading_point}</td>

                <td>{load.rate}</td>
                <td className="text-end">
                  {load.rate_amount_currency_alpha}.{" "}
                  {numberFormatter(load.rate_amount)}
                </td>
                <td>{load.term}</td>
                <td>{load.name}</td>
                <td>{formatDateTime(load.creation_datetime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={paginate}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultCurrent={1}
        current={param.offset + 1}
        pageSize={param.items}
        total={total}
      />
    </div>
  );
}

export default Cargo;
