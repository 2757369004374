import React, { useEffect, useState } from "react";
import Chats from "./chats";
import Messages from "./messages";
import { socket } from "./socket";

function Chat() {
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    socket.connect();
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    // function onFooEvent(value) {
    //   setFooEvents((previous) => [...previous, value]);
    // }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    // socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      // socket.off("foo", onFooEvent);
    };
  }, []);
  return (
    <div className="flex bg-white rounded-md overflow-hidden h-[calc(100vh_-_7rem)]">
      <Chats />
      <Messages />
    </div>
  );
}

export default Chat;
