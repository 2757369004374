import React from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { status } from "../features/statusSlice";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { Link } from "react-router-dom";
import { socket } from "../pages/chat/socket";
import postData from "../utils/API";
import { chatApi } from "../utils/chat-api";

function Header() {
  const dispatch = useDispatch();
  const statusBar = useSelector(status.sideBar);
  const [unread, setUnread] = React.useState(0);

  const msgCount = () => {
    chatApi({
      service: "messages_count",
      data: {},
    }).then((resp) => {
      if (resp.success === 1) setUnread(parseInt(resp.message));
    });
  };

  React.useEffect(() => {
    socket.connect();
    msgCount();
    const messageAlert = (msg) => {
      msgCount();
    };
    const socketListener = {
      message: (msg) => messageAlert(msg),
    };

    socket.on("message", socketListener.message);

    return () => {
      socket.off("message", socketListener.message);
    };
  }, []);

  return (
    <div className="header pe-10">
      <button
        size="large"
        type="text"
        onClick={() => dispatch(status.setSideBar(!statusBar))}
      >
        {!statusBar ? (
          <AiOutlineMenuFold style={{ fontSize: "30px", marginTop: "2px" }} />
        ) : (
          <AiOutlineMenuUnfold style={{ fontSize: "30px", marginTop: "2px" }} />
        )}
      </button>
      <div>Rudi Trip</div>
      <div>
        <Link to="/chats" className="flex items-center gap-2 ">
          <div className="relative pe-1">
            <HiOutlineChatAlt2 className="relative text-3xl" />
            {unread > 0 && (
              <p className="absolute top-0 right-0 text-xs text-white bg-red-600 p-0.5 rounded-full">
                {unread}
              </p>
            )}
          </div>
          <p>Chats</p>
        </Link>
      </div>

      {/* <DarkMode /> */}
    </div>
  );
}

export default Header;
