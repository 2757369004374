import React from "react";
import { Modal, Button, Image } from "antd";
import _ from "lodash";

import { EyeOutlined } from "@ant-design/icons";

import { formatDate, formatDateTime } from "../../utils/utils";

function UserDetail({ user }) {
  const [open, setOpen] = React.useState(false);
  const {
    merchant_id,
    photo,
    name,
    user_type,
    email_id,
    mobile_code,
    phone_no,
    license_no,
    license,
    trucks,
    modification_datetime,
    creation_datetime,
  } = user;

  return (
    <>
      <Button
        type="primary"
        icon={<EyeOutlined />}
        onClick={() => {
          setOpen(true);
        }}
      />

      <Modal
        title={_.startCase(_.toLower(name))}
        open={open}
        width="600px"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[]}
      >
        <div>
          {photo.length > 0 && (
            <div className=" max-h-60 h-60">
              <Image
                src={photo}
                alt={name}
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "contain" }}
              />
            </div>
          )}
          <table>
            <tbody>
              <tr>
                <td>Merchant ID</td>
                <td>{merchant_id}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{_.startCase(_.toLower(name))}</td>
              </tr>
              <tr>
                <td>User Type</td>
                <td>{user_type}</td>
              </tr>
              {email_id.length > 0 && (
                <tr>
                  <td>Email Address</td>
                  <td>{email_id}</td>
                </tr>
              )}
              <tr>
                <td>Phone number</td>
                <td>
                  +{mobile_code > 0 && mobile_code}
                  {phone_no}
                </td>
              </tr>
              {trucks > 0 && (
                <tr>
                  <td>Trucks owned</td>
                  <td>{trucks}</td>
                </tr>
              )}
              {license_no !== null && (
                <tr>
                  <td>License No</td>
                  <td>{license_no}</td>
                </tr>
              )}
              {modification_datetime !== null && (
                <tr>
                  <td>Last seen</td>
                  <td>{formatDateTime(modification_datetime)}</td>
                </tr>
              )}
              <tr>
                <td>Joined on</td>
                <td>{formatDate(creation_datetime)}</td>
              </tr>
            </tbody>
          </table>

          {license.length !== 0 && (
            <Image.PreviewGroup>
              <h2 className="mb-3 font-semibold text-base">Licence images</h2>
              <div className="flex max-h-36 h-36 gap-3">
                {_.map(license, (l, i) => (
                  <Image
                    key={i}
                    src={l}
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "contain" }}
                    alt={name}
                  />
                ))}
              </div>
            </Image.PreviewGroup>
          )}
        </div>
      </Modal>
    </>
  );
}

export default UserDetail;
