import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import postData from "../../utils/API";
import {
  Pagination,
  Button,
  Input,
  Tooltip,
  Typography,
  Modal,
  notification,
} from "antd";

import {
  EyeOutlined,
  RollbackOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { numberWithCommas, numberWithCommasExtra } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { status } from "../../features/statusSlice";
import LoanDetails from "./loan-details";
const dateFormat = require("date-and-time");

const { Search, TextArea } = Input;
const { Text } = Typography;
const { confirm } = Modal;
function Loans() {
  const dispatch = useDispatch();
  const [path, setPath] = React.useState("");
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [loan, setLoan] = React.useState({});
  const [footer, setFooter] = React.useState([]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [decline, setDecline] = React.useState("");

  const [param, setParam] = React.useState({
    items: "10",
    offset: "0",
    search: "",
    status: 3,
  });
  let location = useLocation();

  const getData = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "loans",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success !== undefined && data.success === 1) {
        setData(data.data);
        setTotal(data.items);
      }
    });
  };

  const approveLoan = (l) => {
    confirm({
      title: `Do you want to Approve this loan?`,
      icon: <ExclamationCircleFilled />,
      content: `When clicked the OK button, this loan request will be Approved"
      `,
      onOk() {
        return new Promise((resolve, reject) => {
          postData({
            service: "approve_loan",
            data: { loan_id: l.id },
          }).then((data) => {
            resolve();
            setOpen(false);
            notification.open({
              message: "Loan approved",
              description: data.message,
              duration: 5,
            });
            param.offset = 0;
            setParam({ ...param });
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const handleDecline = () => {
    if (decline.trim().length < 5)
      notification.open({
        message: "Decline reason",
        description: "Please provide a decline reason for atleast 5 characters",
        duration: 5,
      });
    else {
      setOpen(false);
      setIsModalOpen(false);
      postData({
        service: "decline_loan",
        data: { loan_id: loan.id, reason: decline },
      }).then((data) => {
        setDecline("");
        notification.open({
          message: "Loan declined",
          description: data.message,
          duration: 5,
        });
        param.offset = 0;
        setParam({ ...param });
      });
    }
  };

  React.useEffect(() => {
    document.title = "Loans";
    const url = location.pathname.substring(1);
    if (path !== url) {
      param.items = 10;
      param.offset = 0;
      setParam({ ...param });
      setPath(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  const onSearch = (value) => {
    param.offset = 0;
    param.search = value;
    setParam({ ...param });
  };

  return (
    <div className="content">
      <Modal
        title="Decline Loan"
        open={isModalOpen}
        onOk={handleDecline}
        onCancel={() => {
          setDecline("");
          setIsModalOpen(false);
        }}
      >
        <TextArea
          value={decline}
          onChange={(e) => setDecline(e.target.value)}
          rows={4}
          placeholder="Enter reason for declining"
        />
      </Modal>
      <Modal
        title="Loan request"
        width="1000px"
        open={open}
        style={{
          top: 0,
        }}
        footer={footer}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <LoanDetails loan={loan} />
      </Modal>
      <div className="content-header-container">
        <p className="content-header">Loans ({numberWithCommas(total)})</p>
        <div className="pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={param.items}
              current={param.offset + 1}
              total={51}
            />
          </div>

          <div>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              allowClear
              style={{
                width: 200,
              }}
            />
            {/* <div className="space-btn"></div> */}
            <Tooltip placement="topLeft" title={"Reload"}>
              <Button
                onClick={() => {
                  param.offset = 0;
                  setParam({ ...param });
                }}
                style={{ marginLeft: "10px" }}
                icon={<RollbackOutlined />}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Cargo owner</th>
            <th>Currency</th>
            <th>Invoice amount</th>
            <th>Loan amount</th>
            <th>Rate (%)</th>
            <th>Repayable</th>
            <th>Status</th>
            <th>Requested on</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={11}>
                <p className="no-data">No data</p>
              </td>
            </tr>
          )}
          {_.map(data, (d, i) => (
            <tr key={i}>
              <td>{param.offset * param.items + (i + 1)}</td>
              <td>{d.cargo_owner}</td>
              <td>{d.alpha3}</td>
              <td>{numberWithCommasExtra(d.amount)}</td>
              <td>{numberWithCommasExtra(d.loan_amount)}</td>
              <td>{d.interest}</td>
              <td>
                {numberWithCommasExtra(
                  (d.interest / 100) * d.loan_amount + d.loan_amount
                )}
              </td>
              <td>
                {d.rudi === 0 && <Text type="warning">Pending</Text>}
                {d.rudi === 1 && <Text type="success">Approved</Text>}
                {d.rudi === 2 && <Text type="danger">Declined</Text>}
              </td>

              <td>
                {dateFormat.format(
                  new Date(d.creation_datetime),
                  "DD MMMM, YYYY"
                )}
              </td>

              <td>
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setLoan(d);
                    if (d.rudi === 0)
                      setFooter([
                        <Button type="primary" onClick={() => approveLoan(d)}>
                          Approve
                        </Button>,
                        <Button
                          type="primary"
                          danger
                          onClick={() => setIsModalOpen(true)}
                        >
                          Decline
                        </Button>,
                        <Button key="back" onClick={() => setOpen(false)}>
                          Close
                        </Button>,
                      ]);
                    else setFooter([]);

                    setOpen(true);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        onChange={paginate}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultCurrent={1}
        current={param.offset + 1}
        pageSize={param.items}
        total={total}
      />
    </div>
  );
}

export default Loans;
