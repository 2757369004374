// import { getToken } from "../utils/useToken";
import _ from "lodash";

const token =
  "kwyIvZtt1W6aCCmxsQTAkHDe6rHL1yxcIEuNl7Dn74W35TAa9r8XKQAu6fi2tqvssomCgV9hdxXQIMmmtUBqTy41GjwcGhq2vdsgOmtEYtUDiiGJZ3SWKFsDhbCuBTVCuMRBvMFrKhqFkME1h3NZ6RDjanjcT293n2SJfyzUN3oUT3vSXKhLdQVCUfp3xr0KSswVCOVG";

const url = "https://api.ruditrip.com/api";

async function chatApi(data = {}) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const resp = await response.json();

    if (resp.success === -1) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload(false);
    }
    return resp;
  } catch (e) {
    if (_.includes(e.message, "Forbidden")) return { success: -1 };
    // alert("Check you internet connection");
    return { success: 0 };
  }
  // parses JSON response into native JavaScript objects
}
export { chatApi };
