import React, { useState } from "react";
import { chatApi } from "../../utils/chat-api";
import { socket } from "./socket";
import _ from "lodash";
import ChatItem from "./chat-item";
import { useSelector } from "react-redux";
import { chat } from "../../features/chatSlice";
import { CiChat2 } from "react-icons/ci";
import { Modal, Select, Input, Button } from "antd";
import postData from "../../utils/API";
import { IoMdSend } from "react-icons/io";

const { TextArea } = Input;

function Chats() {
  document.title = "Chats";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [userM, setUserM] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (userM.length === 0) alert("Please select user(s)");
    else if (message.trim().length === 0) alert("Please type your message");
    else {
      socket.emit("message-many", { user: 66, with: userM, message });
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const chatData = useSelector(chat.with);

  const [data, setData] = useState([]);
  const [users, setUser] = React.useState([]);

  const getUsers = () => {
    postData({
      service: "app_users",
      data: { offset: "0", items: "100", search: "" },
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        const yu = _.map(
          _.filter(data.data, function (o) {
            return o.user_type !== "Cargo Owner";
          }),
          (u, i) => {
            return {
              label: u.name,
              value: u.user_id + ";-;" + u.name,
            };
          }
        );

        setUser(yu);
      }
    });
  };

  const getChats = () => {
    chatApi({ service: "conversations", data: {} }).then((resp) => {
      if (resp.success === 1) setData(resp.data);
    });
  };

  const chatFilter = (user) => {
    if (
      _.filter(data, (c) => {
        return c.user.user_id === user;
      }) !== 0
    )
      getChats();
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  React.useEffect(() => {
    getChats();
    const socketListener = {
      online: (data) => chatFilter(data),
      offline: (data) => chatFilter(data),
      message: (msg) => chatFilter(msg.message.user_id),
    };

    socket.on("online", socketListener.online);
    socket.on("offline", socketListener.offline);
    socket.on("message", socketListener.message);

    return () => {
      socket.off("online", socketListener.online);
      socket.off("offline", socketListener.offline);
      socket.off("message", socketListener.message);
    };
  }, []);

  const handleChange = (e) => {
    const um = _.map(e, (u) => {
      return u.split(";-;")[0];
    });
    setUserM(um);
  };

  return (
    <div className="w-[20rem] min-w-[20rem]  h-full overflow-y-auto relative">
      <div className="relative">
        <p className="px-4 py-3 font-medium border-b">Chats</p>
        {data.map((chat, i) => (
          <ChatItem key={i} chatData={chat} />
        ))}
      </div>
      <button
        onClick={showModal}
        className="absolute bottom-3 right-2 p-2.5 text-white bg-blue-500 rounded-full"
      >
        <CiChat2 className="text-3xl" />
      </button>

      <Modal
        title="Start conversation"
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" onClick={handleOk} icon={<IoMdSend />}>
            Send
          </Button>,
        ]}
      >
        <div>
          <p className="mb-2">User (s)</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            onChange={handleChange}
            options={users}
          />

          <p className="mt-3 mb-2">Message</p>
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={4}
            placeholder="Type message here...."
          />
        </div>
      </Modal>
    </div>
  );
}

export default Chats;
