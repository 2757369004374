import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  with: 0,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialState,
  reducers: {
    addWith: (state, action) => {
      state.with = action.payload;
    },
  },
});

export const { addWith } = chatSlice.actions;

export const chat = {
  with: (state) => state.chat.with,
  setWith: (data) => (dispatch) => {
    dispatch(addWith(data));
  },
};

export default chatSlice.reducer;
