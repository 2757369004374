import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { chat } from "../../features/chatSlice";
import avatar from "../../assets/images/avatar.png";

function ChatItem({ chatData }) {
  const dispatch = useDispatch();
  const cWith = useSelector(chat.with);

  console.log(chatData);

  const selectChat = () => {
    if (chatData.user1 != 66) dispatch(chat.setWith(chatData.user1));
    else dispatch(chat.setWith(chatData.user2));
    chatData.unread = 0;
  };
  return (
    <div
      className={`flex cursor-pointer items-center gap-2.5 text-sm px-3 py-2 border-b ${
        chatData.user.user_id === cWith && "border-s-4 border-blue-400"
      }`}
      onClick={selectChat}
    >
      <img
        src={chatData.user.photo.length === 0 ? avatar : chatData.user.photo}
        alt={chatData.user.name}
        className="object-cover w-12 h-12 rounded-full min-w-12"
      />
      <div className="w-full ">
        <h2 className="font-semibold">{chatData.user.name}</h2>
        <p className="mt-1 text-gray-600 line-clamp-1">{chatData.message}</p>
      </div>
      <div className="text-xs">
        {chatData.user.is_online === 1 ? (
          <p className="text-green-600">Online</p>
        ) : (
          <p className="text-gray-600">Offline</p>
        )}

        <div className="flex justify-end">
          {chatData.unread > 0 && (
            <p className="px-1.5 py-0.5 text-xs text-white bg-red-600 rounded-full">
              {chatData.unread}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatItem;
