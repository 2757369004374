// import { getToken } from "../utils/useToken";
import _ from "lodash";

const getToken = () => {
  const tokenString = localStorage.getItem("token");
  // const tokenString = sessionStorage.getItem("token");
  return tokenString;
};

// const url = "http://localhost:8000/admin";
const url = "https://api.ruditrip.com/admin";

async function postData(data = {}) {
  var token = getToken();
  if (token) token = token.replace(/['"]+/g, "");
  // status.setStatus;
  // Default options are marked with *
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const resp = await response.json();
    if (resp.success === 0) {
      return { success: 0, message: resp.message };
    } else if (resp.success === 1) {
      return resp;
    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload(false);
    }
    return { success: -1 };
  } catch (e) {
    if (_.includes(e.message, "Forbidden")) return { success: -1 };
    // alert("Check you internet connection");
    return { success: 3 };
  }
  // parses JSON response into native JavaScript objects
}

async function postAppData(data = {}) {
  var token =
    "sdL47IpcjvBwV6QKPEiGFGNfLMpwCdSgmbgvT5BdVW10RMRwpjhbcVg6KPxm033BLoOOcth9uYfmLyJ70wk5VguX1XdD1bMMzjh79x87KICrCtBeMFJDVmrXylrEumFlRxpBQYlGAYMkdJpyyJ9GIJSdhtfO1ZGcyd3Sg1EiEy4S2z5EOVE7bY2Qbq7zcbLYeoeCAjkH";
  if (token) token = token.replace(/['"]+/g, "");
  // status.setStatus;
  // Default options are marked with *
  try {
    const response = await fetch("https://api.ruditrip.com/api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const resp = await response.json();
    if (resp.success === 0) {
      return { success: 0, message: resp.message };
    } else if (resp.success === 1) {
      return resp;
    }
    return { success: -1 };
  } catch (e) {
    if (_.includes(e.message, "Forbidden")) return { success: -1 };
    // alert("Check you internet connection");
    return { success: 3 };
  }
  // parses JSON response into native JavaScript objects
}

// export const postDataAuth = async (data = {}) => {
//   const url = require("./utils").base_url + "auth";
//   // Default options are marked with *
//   try {
//     const response = await fetch(url, {
//       method: "POST", // *GET, POST, PUT, DELETE, etc.
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//       body: JSON.stringify(data), // body data type must match "Content-Type" header
//     });
//     const resp = await response.json();
//     if (resp.success === 0) {
//       alert(resp.message);
//     } else if (resp.success === 1) {
//       return resp;
//     }
//     return { success: -1 };
//   } catch (e) {
//     // alert("Check you internet connection");
//     return { success: 3 };
//   }
//   // parses JSON response into native JavaScript objects
// };
export default postData;

export { postAppData };
