import React from "react";
import _ from "lodash";
import { numberWithCommas } from "../../utils/utils";

import { useSelector } from "react-redux";
import { dashboard } from "../../features/dashboardSlice";
import { Link } from "react-router-dom";

function importAll(r) {
  let images = {};
  // eslint-disable-next-line array-callback-return
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/images/items", false, /\.(png|jpe?g|svg)$/)
);

function Dashboard() {
  document.title = "Dashboard";

  const data = useSelector(dashboard.dashboard);

  const items = [
    {
      label: "Pending Loans",
      icon: images["loan.png"],
      figure: data.pending_loans,
      link: "pending-loans",
    },
    {
      label: "Pending trucks",
      icon: images["truck.png"],
      figure: data.pending_trucks,
      link: "pending-trucks",
    },
    {
      label: "Verified trucks",
      icon: images["trucks.png"],
      figure: data.operating_trucks,
      link: "approved-trucks",
    },
    {
      label: "Drivers",
      icon: images["drivers.png"],
      figure: data.drivers,
      link: "drivers",
    },
  ];

  return (
    <div className="">
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
        {_.map(items, (item, i) => (
          <div key={i} span={6}>
            <Link to={`/${item.link}`} className="link_to">
              <div className="flex justify-between h-full p-4 bg-white rounded-md shadow-md">
                <div>
                  <h5 className="text-3xl font-bold">
                    {numberWithCommas(item.figure)}
                  </h5>
                  <p className="mt-2 text-lg">{item.label}</p>
                </div>
                <img src={item.icon} alt="icon" className="h-16" />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
