import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import postData from "../../utils/API";
import { Image, Pagination } from "antd";
import { Markup } from "interweave";

import { numberWithCommas } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { status } from "../../features/statusSlice";
import AddBlog from "./add-blog";
import RemoveBlog from "./remove-blog";
import BlogDetails from "./blog-details";

function Blogs() {
  const dispatch = useDispatch();
  const [path, setPath] = React.useState("");
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(10);

  const [param, setParam] = React.useState({
    items: "10",
    offset: "0",
  });
  let location = useLocation();

  const getTrucks = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "blog_posts",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success !== undefined && data.success === 1) {
        setData(data.data);
        setTotal(data.data.length);
      }
    });
  };
  React.useEffect(() => {
    document.title = "Our Blog";
    const url = location.pathname.substring(1);
    if (path !== url) {
      param.items = 10;
      param.offset = 0;
      setParam({ ...param });
      setPath(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    getTrucks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };


  const reload = () => {
    param.offset = 0
    setParam({ ...param });
  }
  return (
    <div className="content">
      <div className="content-header-container">
        <p className="content-header">Our Blog ({numberWithCommas(total)})</p>
        <div className="flex justify-between pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={param.items}
              current={param.offset + 1}
              total={51}
            />
          </div>
          <AddBlog reload={reload} />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Cover pic</th>
              <th>Title</th>
              <th>Description</th>
              <th>Date added</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="no-data">No data</p>
                </td>
              </tr>
            )}
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{param.offset * param.items + (i + 1)}</td>
                <td>{d.image.length > 0 && <Image src={d.image} style={{ height: 100, objectFit: "contain" }} />}</td>
                <td>{d.title}</td>
                <td>
                  <p className="break-all whitespace-normal line-clamp-3"><Markup content={d.description} /></p>
                </td>
                <td>{d.creation_datetime}</td>
                <td>
                  <div className="flex gap-3">
                    <BlogDetails post={d} />
                    <RemoveBlog post={d} reload={reload} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={paginate}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultCurrent={1}
        current={param.offset + 1}
        pageSize={param.items}
        total={total}
      />
    </div>
  );
}

export default Blogs;
