import React from "react";
import { Image } from "antd";

function ImageGrid(props) {
  const images = props.images;

  return (
    <div
      style={{
        border: "1px solid rgba(100,100,100,0.3)",
        marginTop: "10px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Image.PreviewGroup>
        {images.length >= 5 && fiveImages()}
        {images.length === 4 && fourImages()}
        {images.length === 3 && threeImages()}
        {images.length === 2 && twoImages()}
        {images.length === 1 && oneImage()}
      </Image.PreviewGroup>
    </div>
  );

  function fiveImages() {
    return (
      <div
        className={`truck-images ${
          props.others === false ? "truck-images-main" : "truck-images-others"
        }`}
      >
        <div>
          <Image src={images[0]} width={"100%"} height={"100%"} alt="main" />
        </div>
        <div className="top-bottom-images">
          <div>
            <div>
              <Image
                width={"100%"}
                height={"100%"}
                src={images[1]}
                alt="main"
              />
            </div>
            <div>
              <Image
                width={"100%"}
                height={"100%"}
                src={images[2]}
                alt="main"
              />
            </div>
          </div>
          <div>
            <div>
              <Image
                width={"100%"}
                height={"100%"}
                src={images[3]}
                alt="main"
              />
            </div>
            <div>
              <Image
                width={"100%"}
                height={"100%"}
                src={images[4]}
                alt="main"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function fourImages() {
    return (
      <div
        className={`truck-images ${
          props.others === false ? "truck-images-main" : "truck-images-others"
        }`}
      >
        <div className="top-bottom-images">
          <div>
            <Image width={"100%"} height={"100%"} src={images[0]} alt="main" />
          </div>
          <div>
            <Image width={"100%"} height={"100%"} src={images[1]} alt="main" />
          </div>
        </div>
        <div className="top-bottom-images">
          <div>
            <Image width={"100%"} height={"100%"} src={images[2]} alt="main" />
          </div>
          <div>
            <Image width={"100%"} height={"100%"} src={images[3]} alt="main" />
          </div>
        </div>
      </div>
    );
  }

  function threeImages() {
    return (
      <div
        className={`truck-images ${
          props.others === false ? "truck-images-main" : "truck-images-others"
        }`}
      >
        <div>
          <Image src={images[0]} width={"100%"} height={"100%"} alt="main" />
        </div>
        <div className="top-bottom-images">
          <div>
            <Image width={"100%"} height={"100%"} src={images[1]} alt="main" />
          </div>
          <div>
            <Image width={"100%"} height={"100%"} src={images[2]} alt="main" />
          </div>
        </div>
      </div>
    );
  }

  function twoImages() {
    return (
      <div
        className={`truck-images ${
          props.others === false ? "truck-images-main" : "truck-images-others"
        }`}
      >
        <div>
          <Image src={images[0]} width={"100%"} height={"100%"} alt="main" />
        </div>

        <div style={{ marginLeft: "5px" }}>
          <Image src={images[1]} width={"100%"} height={"100%"} alt="main" />
        </div>
      </div>
    );
  }

  function oneImage() {
    return (
      <div
        className={`truck-images ${
          props.others === false ? "truck-images-main" : "truck-images-others"
        }`}
      >
        <div>
          <Image src={images[0]} width={"100%"} height={"100%"} alt="main" />
        </div>
      </div>
    );
  }
}

export default ImageGrid;
