import React, { useState } from "react";
import postData from "../../utils/API";
import truck from "../../assets/images/truck.png";
import logo from "../../assets/images/logo.png";
import { Input, message } from "antd";

function Login() {
  document.title = "Login";

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [disable, setDisable] = useState(false);
  const [loginText, setLoginText] = useState("Login");

  const saveToken = (userToken) => {
    // sessionStorage.setItem("token", JSON.stringify(userToken));
    localStorage.setItem("token", JSON.stringify(userToken));
  };

  const handleSubmit = async (e) => {
    setDisable(true);
    e.preventDefault();
    setLoginText("Loading...");
    postData({
      service: "login",
      data: {
        email_id: email,
        password: password,
      },
    }).then((data) => {
      setLoginText("Login");
      if (data.success !== undefined && data.success === 1) {
        saveToken(data.token);
        window.location.reload(false);
      } else {
        setDisable(false);
        if (data.success === 1) message.success(data.message);
        else message.error(data.message);
      }
    });
  };

  return (
    <div className="grid sm:grid-cols-2">
      <div className="flex items-center justify-center w-full h-full bg-white">
        <form onSubmit={handleSubmit}>
          <div className="max-w-md p-10">
            <div className="flex justify-center w-full mb-4">
              <img src={logo} alt="logo" className="w-32" />
            </div>
            <p className="mb-4 text-2xl font-bold">Login to Rudi</p>
            <Input
              placeholder="Email Address"
              className="px-3 py-2.5 text-sm"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input.Password
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              className="px-3 py-2.5 text-sm mt-3"
              required
            />
            <button
              disabled={disable}
              type="submit"
              className="w-full p-2.5 mt-3 text-sm text-white bg-orange-400 rounded-md"
            >
              {loginText}
            </button>
          </div>
        </form>
      </div>
      <div
        style={{ backgroundImage: `url(${truck})` }}
        className="h-32 bg-fixed bg-center bg-no-repeat bg-cover sm:h-screen"
      />
    </div>
  );
}

export default Login;
