import { configureStore } from "@reduxjs/toolkit";

import statusReducer from "./features/statusSlice";
import dashboardReducer from "./features/dashboardSlice";
import chatReducer from "./features/chatSlice";

export default configureStore({
  reducer: {
    status: statusReducer,
    dashboard: dashboardReducer,
    chat: chatReducer,
  },
});
