import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "./pages/welcome/login";
import Content from "./layout";

const getToken = () => {
  const tokenString = localStorage.getItem("token");
  // const tokenString = sessionStorage.getItem("token");
  return tokenString;
};

function App() {
  const token = getToken();

  if (!token) {
    return <Login />;
  }

  return (
    <div className="App">
      <Router>
        <Content />
      </Router>
    </div>
  );
}

export default App;
