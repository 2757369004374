import React from "react";
import SideMenu from "./side-menu";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import postData from "../utils/API";
import { dashboard } from "../features/dashboardSlice";
import { status } from "../features/statusSlice";
import { LinearProgress } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Currency from "../pages/currency";
import Trucks from "../pages/truck";
import TruckMap from "../pages/truck/truck-map";
import TruckOwners from "../pages/users/truck-owner";
import TruckDrivers from "../pages/users/truck-drivers";
import Cargo from "../pages/cargo";
import Loads from "../pages/truck/loads";
import Loans from "../pages/finance/loans";
import PendingLoans from "../pages/finance/pending-loans";
import ApprovedLoans from "../pages/finance/approved-loans";
import DeclinedLoans from "../pages/finance/declined-loans";
import TruckType from "../pages/truck/truck-type";
import Users from "../pages/users";
import NotFound from "../pages/others/notFound";
import Wallet from "../pages/wallet";
import CargoOwners from "../pages/users/cargo-owner";
import Chat from "../pages/chat";
import Referrals from "../pages/referrals";
import Blogs from "../pages/blog";

function Content() {
  const dispatch = useDispatch();
  const getDashboard = () => {
    postData({
      service: "dashboard",
      data: {},
    }).then((data) => {
      if (data.success !== undefined && data.success === 1) {
        dispatch(dashboard.setDashboard(data.data));
      }
    });
  };

  React.useEffect(() => {
    const sec = 30;
    const interval = setInterval(() => {
      getDashboard();
    }, sec * 1000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getDashboard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <SideMenu />
      <div className="w-full h-screen overflow-y-auto">
        <Header />
        {useSelector(status.status) && (
          <LinearProgress
            style={{ position: "fixed", width: "100%", top: "60px" }}
          />
        )}

        <div className="relative p-4">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/trucks" element={<Trucks status={3} />} />
            <Route path="/pending-trucks" element={<Trucks status={0} />} />
            <Route path="/approved-trucks" element={<Trucks status={1} />} />
            <Route path="/declined-trucks" element={<Trucks status={2} />} />
            <Route path="/truck-owner" element={<TruckOwners />} />
            <Route path="/cargo-owner" element={<CargoOwners />} />
            <Route path="/drivers" element={<TruckDrivers />} />
            <Route path="/map" element={<TruckMap />} />
            <Route path="/cargo" element={<Cargo />} />
            <Route path="/loads" element={<Loads />} />
            <Route path="/loans" element={<Loans />} />
            <Route path="/pending-loans" element={<PendingLoans />} />
            <Route path="/approved-loans" element={<ApprovedLoans />} />
            <Route path="/declined-loans" element={<DeclinedLoans />} />
            <Route path="/referrals" element={<Referrals />} />
            <Route path="/truck-types" element={<TruckType />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/currency" element={<Currency />} />
            <Route path="/users" element={<Users />} />
            <Route path="/wallet" element={<Wallet type="all" />} />
            <Route path="/deposits" element={<Wallet type="deposit" />} />
            <Route path="/withdraws" element={<Wallet type="withdraw" />} />
            <Route path="/chats" element={<Chat />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Content;
