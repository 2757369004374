import React from "react";
import { formatDateTime } from "../../../utils/utils";

function LeftMessage({ message }) {
  return (
    <div className="mb-2 ">
      <div className="flex max-w-lg text-sm">
        <p className="px-3 py-2 bg-gray-200 rounded">{message.message}</p>
      </div>
      <p className="text-xs mt-0.5">{formatDateTime(message.createdAt)}</p>
    </div>
  );
}

export default LeftMessage;
