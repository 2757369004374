import React from "react";
import { formatDateTime } from "../../../utils/utils";

function RightMessage({ message }) {
  return (
    <div className="mb-2 ">
      <div className="flex justify-end">
        <div className="flex justify-end max-w-lg text-sm">
          <p className="px-3 py-2 text-white bg-blue-400 rounded">
            {message.message}
          </p>
        </div>
      </div>
      <p className="text-xs mt-0.5 text-end">
        {formatDateTime(message.createdAt)}
      </p>
    </div>
  );
}

export default RightMessage;
