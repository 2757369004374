import { Button, Input, Modal, Upload, message } from "antd";
import React, { useState, useRef } from "react";
import postData from "../../utils/API";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function AddBlog({ reload }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (image.length === 0) {
      message.error("Please attach an image")
      return
    } else if (title.length === 0) {
      message.error("Please enter a title")
      return
    } else if (desc.length === 0) {
      message.error("Please a description")
      return
    } else {
      setIsModalOpen(false);
      postData({
        "service": "post_blog",
        "data": {
          title,
          image,
          "description": desc
        }
      }).then((resp => {
        if (resp.success === 1) message.success(resp.message)
        else message.error(resp.message)
        setTitle("")
        setImage("")
        setFileList([])
        reload()
      }))
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const [fileList, setFileList] = useState([]);
  const props = {
    name: "image",
    multiple: false,
    action: "https://api.ruditrip.com/image_upload",
    onChange(info) {
      setFileList(info.fileList);
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setImage(info.file.response.data[info.file.response.data.length - 1]);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      if (status === "removed") {
        setImage("")
        // setFileList([]);
      }
    },

    onPreview: async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
    onDrop(e) { },
  };

  return (
    <>
      <Modal
        title="Add Blog Post"
        width={1000}
        open={isModalOpen}
        onOk={handleOk}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <p className="m-2">Image</p>

        <Upload
          listType="picture-card"
          fileList={fileList}
          {...props}
        >
          {fileList.length < 1 && "+ Upload"}
        </Upload>

        <p className="m-2">Title</p>
        <Input type="text" placeholder="Enter post title" value={title} onChange={(e) => setTitle(e.target.value)} />
        <p className="m-2">Description</p>
        <CKEditor
          editor={ClassicEditor}
          data=""

          onChange={(event, editor) => {
            setDesc(editor.getData());
          }}

        />
      </Modal>
      <Button type="primary" onClick={showModal}>
        Add Post
      </Button>
    </>
  );
}

export default AddBlog;
