import { Button, Modal, message } from 'antd'
import React from 'react'
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import postData from '../../utils/API';

const { confirm } = Modal;

function RemoveBlog({ post, reload }) {
    const deleteBlog = () => {
        confirm({
            title: 'Do you want to delete this item?',
            icon: <ExclamationCircleFilled />,
            content: 'When clicked the OK button, the post will be removed',
            onOk() {
                return new Promise((resolve, reject) => {
                    postData({
                        "service": "delete_post",
                        "data": {
                            "id": post.id
                        }
                    }).then((resp) => {
                        message.success(resp.message)
                        reload()
                        resolve()
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };
    return (
        <>
            <Button type="primary" danger icon={<DeleteOutlined />} onClick={deleteBlog} />
        </>
    )
}

export default RemoveBlog
