import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import postData from "../../utils/API";
import { Pagination, Typography } from "antd";
import { numberWithCommas } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { status } from "../../features/statusSlice";
const { Text } = Typography;

function TruckTypes() {
  const dispatch = useDispatch();
  const [path, setPath] = React.useState("");
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(10);

  const [param, setParam] = React.useState({
    items: "10",
    offset: "0",
    search: "",
  });
  let location = useLocation();

  const getTrucks = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "truck_types",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success !== undefined && data.success === 1) {
        setData(data.data);
        setTotal(data.data.length);
      }
    });
  };
  React.useEffect(() => {
    document.title = "Truck kinds";
    const url = location.pathname.substring(1);
    if (path !== url) {
      param.items = 10;
      param.offset = 0;
      setParam({ ...param });
      setPath(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    getTrucks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  return (
    <div className="content">
      <div className="content-header-container">
        <p className="content-header">
          Truck kinds ({numberWithCommas(total)})
        </p>
        <div className="pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={param.items}
              current={param.offset + 1}
              total={51}
            />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Truck Type</th>
              <th>Active</th>
            </tr>
          </thead>

          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="no-data">No data</p>
                </td>
              </tr>
            )}
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{param.offset * param.items + (i + 1)}</td>
                <td>{d.type}</td>
                <td>
                  {d.is_deleted === 0 ? (
                    <Text type="success">Active</Text>
                  ) : (
                    <Text type="danger">Inactive</Text>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={paginate}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultCurrent={1}
        current={param.offset + 1}
        pageSize={param.items}
        total={total}
      />
    </div>
  );
}

export default TruckTypes;
