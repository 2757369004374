import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import postData from "../../utils/API";
import { Pagination, Button, Input, Tooltip, Typography } from "antd";

import { RollbackOutlined } from "@ant-design/icons";
import { formatDateTime, numberWithCommas } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { status } from "../../features/statusSlice";
import UserDetail from "./user-detail";
const dateFormat = require("date-and-time");
const { Text } = Typography;

const { Search } = Input;
function TruckDrivers() {
  const dispatch = useDispatch();
  const [path, setPath] = React.useState("");
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(10);

  const [param, setParam] = React.useState({
    items: "10",
    offset: "0",
    search: "",
  });
  let location = useLocation();

  const getTrucks = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "truck_drivers",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success !== undefined && data.success === 1) {
        setData(data.data);
        setTotal(data.items);
      }
    });
  };
  React.useEffect(() => {
    document.title = "Truck drivers";
    const url = location.pathname.substring(1);
    if (path !== url) {
      param.items = 10;
      param.offset = 0;
      setParam({ ...param });
      setPath(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    getTrucks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  const onSearch = (value) => {
    param.offset = 0;
    param.search = value;
    setParam({ ...param });
  };

  return (
    <div className="content">
      <div className="content-header-container">
        <p className="content-header">
          Truck drivers ({numberWithCommas(total)})
        </p>
        <div className="pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={param.items}
              current={param.offset + 1}
              total={51}
            />
          </div>

          <div>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              allowClear
              style={{
                width: 200,
              }}
            />
            {/* <div className="space-btn"></div> */}
            <Tooltip placement="topLeft" title={"Reload"}>
              <Button
                onClick={() => {
                  param.offset = 0;
                  setParam({ ...param });
                }}
                style={{ marginLeft: "10px" }}
                icon={<RollbackOutlined />}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Action</th>
              <th>Name</th>
              <th>Contact</th>
              <th>License No</th>
              <th>Verified</th>
              <th>Last seen</th>
              <th>Joined on</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="no-data">No data</p>
                </td>
              </tr>
            )}
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{param.offset * param.items + (i + 1)}</td>
                <td>
                  <UserDetail user={d} />
                </td>

                <td>{_.startCase(_.toLower(d.name))}</td>
                <td>
                  <a href={`tel:+${d.phone_no}`}>+{d.phone_no}</a>
                </td>
                <td>{d.license_no}</td>
                <td>
                  {d.is_verify === 0 && (
                    <Text type="warning">Not verified</Text>
                  )}
                  {d.is_verify === 1 && <Text type="success">Verified</Text>}
                </td>
                <td>{formatDateTime(d.modification_datetime)}</td>
                <td>
                  {dateFormat.format(
                    new Date(d.creation_datetime),
                    "DD MMMM, YYYY"
                  )}
                </td>

                {/* <td style={{ display: "flex" }}>
                <Button type="primary" icon={<EyeOutlined />} />
                <div className="space-btn"></div>
                <Button icon={<CheckOutlined />} />
              </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={paginate}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultCurrent={1}
        current={param.offset + 1}
        pageSize={param.items}
        total={total}
      />
    </div>
  );
}

export default TruckDrivers;
