import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { chat } from "../../features/chatSlice";
import { chatApi } from "../../utils/chat-api";
import { IoMdSend } from "react-icons/io";
import { socket } from "./socket";
import ScrollableFeed from "react-scrollable-feed";
import RightMessage from "./messages/right-message";
import LeftMessage from "./messages/left-message";

function Message() {
  const chatData = useSelector(chat.with);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");

  const messages = () => {
    if (chatData !== null && chatData.length !== 0)
      chatApi({
        service: "messages",
        data: {
          with: chatData,
        },
      }).then((resp) => {
        if (resp.success === 1) setData(resp.data);
      });
  };

  useEffect(() => {
    messages();
  }, [chatData]);

  React.useEffect(() => {
    const socketListener = {
      message: (msg) => {
        if (msg.message.user === chatData) messages();
      },
    };

    socket.on("message", socketListener.message);

    return () => {
      socket.off("message", socketListener.message);
    };
  }, [chatData]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (message.trim().length > 0) {
      socket.emit("message", { user: 66, to: chatData, message });
      setMessage("");
      messages();
    }
  };

  return (
    <div className="relative w-full h-full border-s">
      <p className="px-4 py-3 font-medium border-b">Messages</p>
      {chatData > 0 && (
        <div className="relative h-full bg-white">
          <ScrollableFeed className="relative px-3 py-2 overflow-y-auto chat-message">
            {data.map((m, i) => {
              if (m.fromSelf)
                return <RightMessage message={m.message} key={i} />;
              else return <LeftMessage message={m.message} key={i} />;
            })}
          </ScrollableFeed>

          <form onSubmit={onSubmit}>
            <div className="absolute flex w-full gap-2 px-4 py-2 bg-white border-t msg bottom-14">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full px-3 py-2.5 text-sm bg-gray-100 rounded-full"
                placeholder="Type message here..."
              />
              <button type="submit">
                <IoMdSend />
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Message;
