import React, { useState } from "react";
import { Menu, Modal, Input, notification } from "antd";
import logo from "../assets/images/logo.png";

import { useSelector } from "react-redux";
import { status } from "../features/statusSlice";

import { AiFillDashboard, AiOutlinePullRequest } from "react-icons/ai";
import { FaTruckLoading } from "react-icons/fa";
import { BsTruck, BsCurrencyExchange } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import { GiNewspaper } from "react-icons/gi";
import { TbTruckLoading } from "react-icons/tb";
import { CiMoneyBill, CiLogout, CiLocationOn } from "react-icons/ci";
import { PiSteeringWheel } from "react-icons/pi";
import { TbUsersGroup } from "react-icons/tb";
import { BiUserPin } from "react-icons/bi";
import { VscReferences } from "react-icons/vsc";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { CiWallet } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import { dashboard } from "../features/dashboardSlice";
import postData from "../utils/API";

import { HiOutlineUserGroup } from "react-icons/hi2";

const { confirm } = Modal;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem("Dashboard", "", <AiFillDashboard />),
  getItem("Trucks", "trucks", <BsTruck />, [
    getItem("All trucks", "/trucks"),
    getItem("Pending trucks", "/pending-trucks"),
    getItem("Approved trucks", "/approved-trucks"),
    getItem("Declined trucks", "/declined-trucks"),
  ]),
  getItem("App users", "/users", <TbUsersGroup />),
  getItem("Truck Owners", "/truck-owner", <FaUserTie />),
  getItem("Drivers", "/drivers", <PiSteeringWheel />),
  getItem("Cargo Owners", "/cargo-owner", <HiOutlineUserGroup />),
  getItem("Map", "/map", <CiLocationOn />),
  // getItem("Application users", "/users", <FiUsers />),

  getItem("Cargo", "/cargo", <FaTruckLoading />),
  getItem("Load Requests", "/loads", <AiOutlinePullRequest />),
  getItem("Finance", "finance", <CiMoneyBill />, [
    getItem("All loans", "/loans"),
    getItem("Pending loans", "/pending-loans"),
    getItem("Approved loans", "/approved-loans"),
    getItem("Declined loans", "/declined-loans"),
  ]),
  getItem("Wallet", "wallet", <CiWallet />, [
    getItem("All transactions", "/wallet"),
    getItem("Deposits", "/deposits"),
    getItem("Withdraws", "/withdraws"),
  ]),
  getItem("Referral", "/referrals", <VscReferences />),
  getItem("Our Blog", "/blog", <GiNewspaper />),
  getItem("Truck types", "/truck-types", <TbTruckLoading />),
  getItem("Currency", "/currency", <BsCurrencyExchange />),
  getItem("Change password", "password", <RiLockPasswordFill />),
  getItem("Logout", "logout", <CiLogout />),
];

const rootSubmenuKeys = ["trucks", "finance", "sub4"];

const logoutUser = () => {
  confirm({
    title: "Do you want to logout?",
    icon: <ExclamationCircleFilled />,
    content:
      "When clicked the OK button, you will be logged out and prompted to login again",
    onOk() {
      return new Promise((resolve, reject) => {
        postData({
          service: "logout",
          data: {},
        }).then((data) => {
          resolve();
          sessionStorage.clear();
          localStorage.clear();
          window.location.reload(false);
        });
      }).catch(() => console.log("Oops errors!"));
    },
    onCancel() {},
  });
};

function SideMenu() {
  let location = useLocation();
  const data = useSelector(dashboard.dashboard);
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);
  const [active, setActive] = useState("");

  const [password, setPassword] = useState({
    current_pwd: "",
    new_pwd: "",
  });
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  React.useEffect(() => {
    const url = location.pathname.substring(1);
    setActive(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleOk = () => {
    postData({
      service: "change_password",
      data: password,
    }).then((data) => {
      setIsModalOpen(false);
      if (data.success !== undefined && data.success === 1) {
        password.current_pwd = "";
        password.new_pwd = "";
        setPassword({ ...password });
      } else
        notification.open({
          message: "Change password",
          description: data.message,
          duration: 5,
        });
    });
  };

  const collapsed = useSelector(status.sideBar);

  return (
    <div
      className="nav-bar"
      style={{
        transition: "all 0.5s",
        width: !collapsed ? "285px" : "85px",
        minWidth: !collapsed ? "285px" : "85px",
      }}
    >
      <Modal
        title="Change password"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          password.current_pwd = "";
          password.new_pwd = "";
          setPassword({ ...password });
          setIsModalOpen(false);
        }}
      >
        <br />
        <Input
          value={password.current_pwd}
          placeholder="Currenct password"
          type="password"
          onChange={(e) => {
            password.current_pwd = e.target.value;
            setPassword({ ...password });
          }}
        />
        <br />
        <br />
        <Input
          value={password.new_pwd}
          placeholder="New password"
          type="password"
          onChange={(e) => {
            password.new_pwd = e.target.value;
            setPassword({ ...password });
          }}
        />
      </Modal>
      {!collapsed ? (
        <div className="mb-3">
          <div>
            <h5>Rudi Trip</h5>
          </div>
          {data.profile !== undefined && (
            <div>
              <h6>{data.profile.name}</h6>
              <p>{data.profile.email_id}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="small-section">
          <img src={logo} alt="logo" />
        </div>
      )}
      <Menu
        mode="inline"
        openKeys={openKeys}
        defaultSelectedKeys={[location.pathname.substring(1)]}
        onOpenChange={onOpenChange}
        onClick={(e) => {
          if (e.key === "logout") logoutUser();
          else if (e.key === "password") setIsModalOpen(true);
          else navigate(e.key);
        }}
        inlineCollapsed={collapsed}
        style={{
          marginRight: !collapsed && "65px",
        }}
        items={items}
      />
    </div>
  );
}

export default SideMenu;
