import { Button, Modal, Image } from 'antd'
import React, { useState } from 'react'
import { EyeOutlined } from "@ant-design/icons"
import { Markup } from 'interweave';

function BlogDetails({ post }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => { setIsModalOpen(false); }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal
                title="Blog Post"
                width={800}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Image src={post.image} height={300} width={"100%"} style={{ objectFit: "cover" }} />
                <h2 className='my-3 text-lg font-semibold'>{post.title}</h2>
                <Markup content={post.description} />
            </Modal>
            <Button type="primary" icon={<EyeOutlined />} onClick={showModal} />
        </>
    )
}

export default BlogDetails
