import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import postData from "../../utils/API";
import { Pagination, Typography } from "antd";

import { numberFormatter, numberWithCommas } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { status } from "../../features/statusSlice";
const { Text } = Typography;

function Wallet({ type }) {
  const dispatch = useDispatch();
  const [path, setPath] = React.useState("");
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(10);

  const [param, setParam] = React.useState({
    items: "10",
    offset: "0",
    search: "",
  });
  let location = useLocation();

  const getData = () => {
    param.type = type;
    dispatch(status.setStatus(true));
    postData({
      service: "transactions",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success !== undefined && data.success === 1) {
        setData(data.data);
        setTotal(data.data.length);
      }
    });
  };
  React.useEffect(() => {
    document.title = "Transactions";
    const url = location.pathname.substring(1);

    if (path !== url) {
      param.items = 10;
      param.offset = 0;
      setParam({ ...param });
      setPath(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  return (
    <div className="content">
      <div className="content-header-container">
        <p className="content-header">
          {type.toUpperCase()} Transactions ({numberWithCommas(total)})
        </p>
        <div className="pagination-container">
          <div>
            <p>Show</p>
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={param.items}
              current={param.offset + 1}
              total={51}
            />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Transaction Id</th>
              <th>Transaction Type</th>
              <th>Status</th>
              <th>Amount (KES)</th>
              <th>User</th>
              <th>Description</th>
              <th>Phone</th>
              <th>Email</th>
            </tr>
          </thead>

          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="no-data">No data</p>
                </td>
              </tr>
            )}
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{param.offset * param.items + (i + 1)}</td>
                <td>{d.transaction_id}</td>
                <td className="capitalize">{d.transaction_type}</td>
                <td>
                  {d.status === 0 && <Text>Pending</Text>}
                  {d.status === 1 && <Text type="success">Successful</Text>}
                  {d.status === 2 && <Text type="danger">Declined</Text>}
                  {d.status === 3 && <Text type="warning">Cancelled</Text>}
                </td>
                <td className="text-end">{numberFormatter(d.amount)}</td>
                <td className="capitalize">{d.name}</td>
                <td>{d.description}</td>
                <td>{d.phone}</td>
                <td>{d.email_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={paginate}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultCurrent={1}
        current={param.offset + 1}
        pageSize={param.items}
        total={total}
      />
    </div>
  );
}

export default Wallet;
