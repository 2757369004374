import React from "react";
import { Col, Row } from "react-bootstrap";
import { numberWithCommasExtra } from "../../utils/utils";
import ImageGrid from "../truck/image-grid";
import { Typography } from "antd";

const { Text } = Typography;
function LoanDetails(props) {
  const loan = props.loan;
  return (
    <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
      <div className="truck-details">
        <Row>
          <Col lg={6} md={6}>
            <h4>Invoice</h4>
            <ImageGrid images={loan.invoice} others={true} />
          </Col>
          <Col lg={6} md={6}>
            <h4>Packing list</h4>
            <ImageGrid images={loan.parking_list} others={true} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Cargo owner</h4>
            <p>{loan.cargo_owner}</p>
            <h4>Loading point</h4>
            <p>{loan.loading_point}</p>
            <h4>Offloading point</h4>
            <p>{loan.offloading_point}</p>

            <h4>Truck owner</h4>
            <p>{loan.name}</p>

            <h4>Email</h4>
            <p>
              <a href={`mailto:${loan.email_id}`}>{loan.email_id}</a>
            </p>

            <h4>Contact</h4>
            <p>
              <a href={`tel:+${loan.phone}`}>+{loan.phone}</a>
            </p>
          </Col>
          <Col>
            <h4>Loan amount</h4>
            <p>
              {loan.alpha3}. {numberWithCommasExtra(loan.loan_amount)}
            </p>
            <h4>Invoice amount</h4>
            <p>
              {loan.alpha3}. {numberWithCommasExtra(loan.amount)}
            </p>
            <h4>Interest rate</h4>
            <p>{loan.interest}%</p>

            <h4>Repayable</h4>
            <p>
              {loan.alpha3}.{" "}
              {numberWithCommasExtra(
                (loan.interest / 100) * loan.loan_amount + loan.loan_amount
              )}
            </p>

            <h4>Rudi</h4>
            <p>
              {loan.rudi === 0 && <Text type="warning">Pending</Text>}
              {loan.rudi === 1 && <Text type="success">Approved</Text>}
              {loan.rudi === 2 && <Text type="danger">Declined</Text>}
            </p>

            <h4>Pezesha</h4>
            <p>
              {loan.pezesha === 0 && <Text type="warning">Pending</Text>}
              {loan.pezesha === 1 && <Text type="success">Approved</Text>}
              {loan.pezesha === 2 && <Text type="danger">Declined</Text>}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LoanDetails;
