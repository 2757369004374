import React from "react";
import { Modal, Button, Typography, message, Image } from "antd";

import { EyeOutlined } from "@ant-design/icons";

import { formatDate, formatDateTime, numberFormatter } from "../../utils/utils";
import { AiOutlineEdit } from "react-icons/ai";
import postData from "../../utils/API";

const { Text } = Typography;

const { confirm } = Modal;
function CargoDetail({ cargo }) {
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = React.useState({});
  const [handle, setHandle] = React.useState(false);

  /** Match Status */
  const match = (load) => {
    let ends = [];
    let starts = [];
    let complete = 0;
    load.match.map((m) => {
      if (m.end_trip !== null) ends.push(m);
      if (m.start_trip !== null) starts.push(m);
      return m;
    });

    if (starts.length === ends.length) complete = ends.length;

    if (load.loads === complete) return <Text type="success">Delivered</Text>;
    if (starts.length > 0) return <Text type="warning">ONroute</Text>;
    return <Text type="danger">Pending</Text>;
  };

  const handleRequest = (status) => {
    setHandle(false);
    confirm({
      title: "Do you want to approve this request?",
      content:
        "When clicked the OK button, the truck will be matched to this Load",
      onOk() {
        return new Promise((resolve, reject) => {
          postData({
            service: "match_cargo",
            data: {
              from_find: false,
              cargo_id: cargo.id,
              load_request: request.id,
            },
          }).then((data) => {
            if (data.success === 1) message.success(data.message);
            else message.error(data.message);
            resolve();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() { },
    });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<EyeOutlined />}
        onClick={() => {
          setOpen(true);
        }}
      />

      <Modal
        title={"Handle request"}
        open={handle}
        onOk={() => setHandle(false)}
        onCancel={() => setHandle(false)}
        footer={<Button onClick={() => setHandle(false)}>Close</Button>}
        centered
      >
        {Object.keys(request).length !== 0 && (
          <div className="flex gap-4 mt-4">
            <button
              className="px-6 text-sm btn btn-success"
              onClick={() => {
                handleRequest(true);
              }}
            >
              Accept
            </button>
            {/* <button
              onClick={() => {
                handleRequest(false);
              }}
              className="px-6 text-sm btn btn-danger"
            >
              Decline
            </button> */}
          </div>
        )}
      </Modal>

      <Modal
        title={"Cargo details"}
        open={open}
        width="1200px"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<Button onClick={() => setOpen(false)}>Close</Button>}
        centered
      >
        <div className="grid gap-4 md:grid-cols-2">
          <table>
            <tr>
              <td>Order no</td>
              <td>{cargo.id}</td>
            </tr>
            <tr>
              <td>Commodity</td>
              <td className="capitalize">{cargo.cargo}</td>
            </tr>
            <tr>
              <td>Requests</td>
              <td>{cargo.requests.length}</td>
            </tr>
            <tr>
              <td>Vehicle Type</td>
              <td>{cargo.type}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{match(cargo)}</td>
            </tr>
            <tr>
              <td>Number of trucks</td>
              <td>{numberFormatter(cargo.loads)}</td>
            </tr>
            <tr>
              <td>Weight</td>
              <td>
                {numberFormatter(cargo.weight)} {cargo.weight_type}
              </td>
            </tr>
            <tr>
              <td>Git per load</td>
              <td>
                {cargo.git_currency_alpha}.{" "}
                {numberFormatter(cargo.git_per_load)}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>Loading Point</td>
              <td>{cargo.loading_point}</td>
            </tr>
            <tr>
              <td>Loading Date</td>
              <td>{formatDate(cargo.loading_date)}</td>
            </tr>
            <tr>
              <td>Offloading Point</td>
              <td>{cargo.offloading_point}</td>
            </tr>
            <tr>
              <td>Rate</td>
              <td>{cargo.rate}</td>
            </tr>
            <tr>
              <td>Rate Amount</td>
              <td>
                {cargo.rate_amount_currency_alpha}.{" "}
                {numberFormatter(cargo.rate_amount)}
              </td>
            </tr>
            <tr>
              <td>Payment Term</td>
              <td>{cargo.term}</td>
            </tr>
            <tr>
              <td>Days</td>
              <td>{cargo.days}</td>
            </tr>
            <tr>
              <td>Posted by</td>
              <td>{cargo.name}</td>
            </tr>
            <tr>
              <td>Date Created</td>
              <td>{formatDate(cargo.creation_datetime)}</td>
            </tr>
          </table>
        </div>

        {cargo.match.length > 0 && (
          <div
            className={`relative w-full mb-4 ${cargo.match.length > 0 && "overload-table"
              }`}
          >
            <p className="mb-0 text-xl font-medium">Matched Trucks</p>
            <table className="top-0 w-full h-full overflow-y-auto ">
              <thead>
                <tr>
                  <td>Status</td>
                  <td>Transaction Id</td>
                  <td>Request Id</td>
                  <td>Truck No Plate</td>
                  <td>Trailer No Plate</td>
                  <td>Bid Amount</td>
                  <td>Trip Start</td>
                  <td>Trip End</td>
                  <td>Proof of delivery</td>
                  <td>Driver's name</td>
                  <td>Contact</td>
                </tr>
              </thead>
              <tbody>
                {cargo.match.map((match, i) => (
                  <tr key={i}>
                    <td>
                      {match.start_trip === null && (
                        <Text type="danger">Not started</Text>
                      )}

                      {match.start_trip !== null && match.end_trip === null && (
                        <Text type="warning">ONroute</Text>
                      )}

                      {match.end_trip !== null && (
                        <Text type="success">Completed</Text>
                      )}
                    </td>
                    <td>{match.transaction_id}</td>
                    <td>{match.request_id}</td>
                    <td>{match.truck_no}</td>
                    <td>{match.trailer_no}</td>
                    <td>
                      {match.alpha3} {numberFormatter(match.amount)}
                    </td>
                    <td>
                      {match.start_trip !== null &&
                        formatDateTime(match.start_trip)}
                    </td>
                    <td>
                      {match.end_trip !== null &&
                        formatDateTime(match.end_trip)}
                    </td>
                    <td>
                      {match.proof !== null && (
                        <Image
                          src={`https://api.ruditrip.com/images/${match.proof.split(",")[0]
                            }`}
                          alt="proof"
                          style={{ objectFit: "contain" }}
                          width={150}
                        />
                      )}
                    </td>
                    <td>{match.driver_name}</td>
                    <td>{match.phone}</td>
                  </tr>
                ))}
                {cargo.requests.length === 0 && (
                  <tr>
                    <td colSpan={11}>
                      <p className="p-4 text-center">No Data</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {cargo.requests.length > 0 && (
          <div
            className={`relative w-full mb-4 ${cargo.requests.length > 0 && "overload-table"
              }`}
          >
            <p className="text-xl font-medium">Requests</p>
            <table className="top-0 w-full h-full overflow-y-auto ">
              <thead>
                <tr>
                  <td>Action</td>
                  <td>No</td>
                  <td>Vehicle Type</td>
                  <td>Truck No Plate</td>
                  <td>Trailer No Plate</td>
                  <td>Bid Amount</td>
                  <td>Status</td>
                  <td>Driver's name</td>
                  <td>Contact</td>
                  <td>Truck owner</td>
                  <td>Request on</td>
                </tr>
              </thead>
              <tbody>
                {cargo.requests.map((request, i) => (
                  <tr key={i}>
                    <td>
                      {request.status === 0 && (
                        <button
                          className="flex items-center gap-3 text-sm btn-success btn"
                          onClick={() => {
                            setHandle(true);
                            setRequest(request);
                          }}
                        >
                          <AiOutlineEdit />
                          Handle
                        </button>
                      )}
                    </td>
                    <td>{request.id}</td>
                    <td>{request.type}</td>
                    <td>{request.truck_no}</td>
                    <td>{request.trailer_no}</td>
                    <td>
                      {request.alpha3} {numberFormatter(request.amount)}
                    </td>
                    <td>
                      {request.status === 0 && (
                        <Text type="default">Pending</Text>
                      )}
                      {request.status === 1 && (
                        <Text type="success">Accepted</Text>
                      )}
                      {request.status === 2 && (
                        <Text type="danger">Declined</Text>
                      )}
                    </td>
                    <td>{request.driver_name}</td>
                    <td>{request.phone}</td>
                    <td>{request.name}</td>
                    <td>{formatDateTime(request.creation_datetime)}</td>
                  </tr>
                ))}
                {cargo.requests.length === 0 && (
                  <tr>
                    <td colSpan={11}>
                      <p className="p-4 text-center">No Data</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </Modal>
    </>
  );
}

export default CargoDetail;
