import React from "react";
import { Typography } from "antd";
import { Col, Row } from "react-bootstrap";
import ImageGrid from "./image-grid";
const dateFormat = require("date-and-time");

const { Text } = Typography;

function TruckDetails(props) {
  const truck = props.truck;

  return (
    <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
      <ImageGrid images={truck.truck_images} others={false} />
      <div className="truck-details">
        <Row>
          <Col>
            <h4>Truck number</h4>
            <p>{truck.truck_no}</p>
            <h4>Trailer number</h4>
            <p>{truck.trailer_no}</p>
            <h4>Status</h4>
            <p>
              {truck.is_verify === 0 && (
                <Text type="warning">Pending verification</Text>
              )}
              {truck.is_verify === 1 && (
                <Text type="success">Approved truck</Text>
              )}
              {truck.is_verify === 2 && <Text type="danger">Declined</Text>}
            </p>
            {truck.reason !== null && <p>{truck.reason}</p>}

            <h4>Driver's name</h4>
            <p>{truck.driver_name}</p>

            <h4>Driver's contact</h4>
            <p>{truck.phone}</p>
          </Col>
          <Col>
            <h4>Owner name</h4>
            <p>{truck.owner.name}</p>
            <h4>Owner Email</h4>
            <p>{truck.owner.email_id}</p>
            <h4>Owner Contact</h4>
            <p>
              +{truck.owner.mobile_code}
              {truck.owner.phone_no}
            </p>
            <h4>Registered on</h4>
            <p>
              {dateFormat.format(
                new Date(truck.creation_datetime),
                "DD MMMM, YYYY"
              )}
            </p>

            <h4>Driver's Licence number</h4>
            <p>{truck.driver_license_no}</p>
          </Col>
        </Row>
        <h4>Truck documents</h4>

        <Row>
          <Col lg={4} md={6}>
            <h4>Logbook</h4>
            <ImageGrid images={truck.log_book} others={true} />
          </Col>
          {truck.transit_insurance.length > 0 && (
            <Col lg={4} md={6}>
              <h4>Transit Insurance certificate</h4>
              <ImageGrid images={truck.transit_insurance} others={true} />
            </Col>
          )}
          {truck.licence.length > 0 && (
            <Col lg={4} md={6}>
              <h4>Driver's licence</h4>
              <ImageGrid images={truck.licence} others={true} />
            </Col>
          )}

          {truck.inspection_cert.length > 0 && (
            <Col lg={4} md={6}>
              <h4>Inspection certificate</h4>
              <ImageGrid images={[truck.inspection_cert]} others={true} />
            </Col>
          )}
          {truck.registration_cert.length > 0 && (
            <Col lg={4} md={6}>
              <h4>Company registration certificate</h4>
              <ImageGrid images={[truck.registration_cert]} others={true} />
            </Col>
          )}
          {truck.pin_cert.length > 0 && (
            <Col lg={4} md={6}>
              <h4>PIN/TIN certificate</h4>
              <ImageGrid images={[truck.pin_cert]} others={true} />
            </Col>
          )}

          {truck.insurance.length > 0 && (
            <Col lg={4} md={6}>
              <h4>Truck insurance certificate</h4>
              <ImageGrid images={[truck.insurance]} others={true} />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

export default TruckDetails;
