import React from "react";
import { Empty } from "antd";

function NotFound() {
  document.title = "Not found";

  return (
    <div>
      <Empty description={<span>Page not found</span>} />
    </div>
  );
}

export default NotFound;
